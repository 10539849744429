
<template>
	<div>
		<v-progress-linear :indeterminate="true" v-if="loading"></v-progress-linear>
		<v-container v-else class="my-2">
			<!-- {{user}} -->
			<!-- img -->
			<!-- <v-img :src="profile_attachment.profile_attachment_path" max-height="200" max-width="200"></v-img> -->
			<!-- center -->
			<v-flex xs12 lg2 xl2 md12 sm12 class="text-center" v-if="profile_attachment != null">
				<div v-if="profile_attachment.profile_attachment_path" class="mb-4">

					<v-img max-width="500" v-bind:src="$imageURL + 'items/image/' + profile_attachment.profile_attachment_path" />
				</div>
			</v-flex>
			<h3>{{$store.getters.language.data.employees.employee_name}}: {{employees_detail.employee_name}}</h3>

			<h3>{{$store.getters.language.data.positions.position_name}}: {{employees_detail.position_name}}</h3>
			<h3>{{$store.getters.language.data.employees.supervisor}}: {{employees_detail.supervisor}}</h3>
			<h3>{{$store.getters.language.data.employees.employee_off_days_year}}: {{employees_detail.employee_off_days_year}}</h3>
			<h3>{{$store.getters.language.data.off_days.off_day_this_year}}: {{off_days}}</h3>
			<h3>{{$store.getters.language.data.iligal_days.iligal_day_this_year}}: {{iligal_days}}</h3>
			<!-- remaining days -->
			<h3>{{$store.getters.language.data.employees.employee_remaining_days}}: {{employees_detail.employee_off_days_year - off_days }}</h3>
			<v-divider class="my-4"></v-divider>
			<h3>{{$store.getters.language.data.off_day_requests.off_day_request_date}}: {{ new Date(off_day_requests.off_day_request_date).toISOString().slice(0, 10).replace('T', ' ')}}</h3>
			<h3>{{$store.getters.language.data.off_day_requests.off_day_request_amount}}: {{off_day_requests.off_day_request_amount}}</h3>
			<h3>{{$store.getters.language.data.off_day_requests.off_day_request_insert_date}}: {{ new Date(off_day_dates.insert).toISOString().slice(0, 19).replace('T', ' ')}}</h3>
			<!-- <h3>{{$store.getters.language.data.off_day_requests.off_day_request_type}}: {{off_day_requests.off_day_request_type}}</h3> -->
			<h3 v-if="off_day_requests.off_day_request_type == 'without_salary'">{{$store.getters.language.data.off_day_requests.off_day_request_type}}: <v-icon color="red">mdi-currency-usd-off</v-icon>{{off_day_requests.off_day_request_type}}</h3>
			<h3 v-if="off_day_requests.off_day_request_type == 'off_day'">{{$store.getters.language.data.off_day_requests.off_day_request_type}}: <v-icon color="success">mdi-currency-usd</v-icon>{{off_day_requests.off_day_request_type}}</h3>
			<h3>{{$store.getters.language.data.off_day_requests.off_day_request_note}}: {{off_day_requests.off_day_request_note}}</h3>
			<h3>{{$store.getters.language.data.off_day_requests.off_day_request_status}}: {{off_day_requests.off_day_request_status}}</h3>
			<h3 v-if="off_day_requests.off_day_request_answer_date">{{$store.getters.language.data.off_day_requests.off_day_request_answer_date}}: {{new Date(off_day_requests.off_day_request_answer_date).toISOString().slice(0, 10).replace('T', ' ')}}</h3>
			<form autocomplete="off" class="mt-8">
				<v-layout row wrap>

					<v-flex xs12 lg12 xl12 md12 sm12 v-if="user.position_id==12 || user.position_id==3">
						<v-textarea v-model="off_day_requests.off_day_request_final_note" type="textarea" :label="$store.getters.language.data.off_day_requests.off_day_request_final_note" class="mx-1" filled outlined dense required>
						</v-textarea>
						<v-textarea v-model="off_day_requests.off_day_request_answer_note" type="textarea" :label="$store.getters.language.data.off_day_requests.off_day_request_answer_note" class="mx-1" filled outlined dense disabled>
						</v-textarea>
					</v-flex>
					<v-flex xs12 lg12 xl12 md12 sm12 v-else>
						<v-textarea v-model="off_day_requests.off_day_request_answer_note" type="textarea" :label="$store.getters.language.data.off_day_requests.off_day_request_answer_note" class="mx-1" filled outlined dense required>
						</v-textarea>

						<v-textarea v-model="off_day_requests.off_day_request_final_note" type="textarea" :label="$store.getters.language.data.off_day_requests.off_day_request_final_note" class="mx-1" filled outlined dense disabled>
						</v-textarea>
					</v-flex>
                    <div v-if="user.user_id != null 
                     && off_day_requests.off_day_request_status!='approved'">
					<v-flex xs12 lg2 xl4 md4 sm4 v-if="user.position_id==12 || user.position_id==3">
						<v-btn color="success" type="submit" @click="updateOffDayRequests('approved')">{{$store.getters.language.data.off_day_requests.update_btn_approve}}</v-btn>
					</v-flex>
					<v-flex xs12 lg2 xl4 md4 sm4 v-else>
						<v-btn color="success" type="submit" @click="updateOffDayRequests('viewing')">{{$store.getters.language.data.off_day_requests.update_btn_view}}</v-btn>
					</v-flex>
					<v-flex xs12 lg2 xl4 md4 sm4>
						<v-btn color="error" type="submit" @click="updateOffDayRequests('declined')">{{$store.getters.language.data.off_day_requests.update_btn_decline}}</v-btn>
					</v-flex>
                    </div>
				</v-layout>
			</form>
			<!-- <form @submit.prevent="updateOffDayRequests" autocomplete="off" class="mt-8">
				<v-layout row wrap>

					<v-flex xs12 lg2 xl2 md3 sm4>
						<v-text-field v-model="off_day_requests.off_day_request_date" type="date" :label="$store.getters.language.data.off_day_requests.off_day_request_date" class="mx-1" filled outlined dense required>
						</v-text-field>
					</v-flex>
					<v-flex xs12 lg2 xl2 md3 sm4>
						<v-text-field v-model="off_day_requests.off_day_request_amount" type="number" :label="$store.getters.language.data.off_day_requests.off_day_request_amount" class="mx-1" filled outlined dense required>
						</v-text-field>
					</v-flex>
					<v-flex xs12 lg2 xl2 md3 sm4>
						<v-text-field v-model="off_day_requests.off_day_request_insert_date" type="datetime-local" :label="$store.getters.language.data.off_day_requests.off_day_request_insert_date" class="mx-1" filled outlined dense>
						</v-text-field>
					</v-flex>
					<v-flex xs12 lg2 xl2 md3 sm4>
						<v-text-field v-model="off_day_requests.off_day_request_note" type="textarea" :label="$store.getters.language.data.off_day_requests.off_day_request_note" class="mx-1" filled outlined dense>
						</v-text-field>
					</v-flex>
					<v-flex xs12 lg2 xl2 md3 sm4>
						<v-select class="mx-1" clearable :items="['pending','approved','declined']" v-model="off_day_requests.off_day_request_status" dense filled outlined item-text="off_day_request_status" item-value="off_day_request_status" :return-object="false" :label="$store.getters.language.data.off_day_requests.off_day_request_status">
						</v-select>
					</v-flex>

					<v-flex xs12 lg2 xl2 md3 sm4>
						<v-select class="mx-1" clearable :items="['off_day','without_salary']" v-model="off_day_requests.off_day_request_type" dense filled outlined item-text="off_day_request_type" item-value="off_day_request_type" :return-object="false" :label="$store.getters.language.data.off_day_requests.off_day_request_type">
						</v-select>
					</v-flex>

					<v-flex xs12 lg2 xl2 md3 sm4>
						<v-select class="mx-1" clearable :items="users" v-model="off_day_requests.user_id" dense filled outlined item-text="user_id" item-value="user_id" :return-object="false" :label="$store.getters.language.data.users.user_id">
						</v-select>
					</v-flex>
					<v-flex xs12 lg2 xl2 md3 sm4>
						<v-select class="mx-1" clearable :items="employees" v-model="off_day_requests.employee_id" dense filled outlined item-text="employee_id" item-value="employee_id" :return-object="false" :label="$store.getters.language.data.employees.employee_id">
						</v-select>
					</v-flex>
					<v-flex xs12 lg2 xl2 md2 sm4>
						<v-btn color="success" type="submit">{{$store.getters.language.data.off_day_requests.update_btn}}</v-btn>
					</v-flex>
				</v-layout>
			</form> -->

		</v-container>
		<v-snackbar v-model="snackbar.value" :color="snackbar.color">
			{{snackbar.text}}
		</v-snackbar>
	</div>
</template>
<script>
	import employeesRequest from '../../requests/employees.request.js'
	import requests from './../../requests/off_day_requests.request.js'
	import profileRequests from '../../requests/profile_attachments.request.js'
	import offRequests from '../../requests/off_days.request.js'
    import iligalRequests from '../../requests/iligal_days.request.js'
	export default {
		data() {
			return {
				off_days_data: {},
				request_data: {},
				off_day_requests: {},
                off_day_dates: {},
				employees_detail: {},
				profile_attachment: [],
                // user:{},
				off_days: 0,
				iligal_days: 0,
				id: '',
				loading: true,
				snackbar: {
					value: false,
					text: '',
					color: ''
				},
				idemployee: 0,
			}
		},
		computed: {

			user() {
				return this.$store.getters.user
			},

			// employees() {
			// 	return this.$store.getters.employees_list
			// },

		},
		mounted() {
			this.id = this.$route.params.id
			this.getOneOffDayRequests()
		},
		methods: {
			getOneEmployeeDetail() {
				this.loading = true
				employeesRequest.getEmployeeDetails(this.idemployee).then(r => {
					if (r.status == 200) {
						this.employees_detail = r.data.employee
						this.off_days = r.data.off_days_amount[0].off_day_amount
						this.iligal_days = r.data.iligal_days_amount[0].iligal_days
						this.getOneProfileAttachment()
						this.loading = false
					} else {
						this.snackbar = {
							value: true,
							text: 'Fail to read Employee',
							color: 'error'
						}
					}
				})
					.catch(e => {
						this.snackbar = {
							value: true,
							text: 'Fail to read Employee',
							color: 'error'
						}
					})
					.finally(() => {
						this.loading = false
					})
			},
			addOffDays() {
				this.loading_btn = true
                // console.log(this.request_data)

				this.off_days_data = {
					off_day_date: new Date(this.request_data.off_day_date).toISOString().slice(0, 19).replace('T', ' '),
                    off_day_request_date: new Date(this.request_data.off_day_request_date).toISOString().slice(0, 19).replace('T', ' '),
					off_day_amount: this.off_day_requests.off_day_request_amount,
					off_day_note: this.off_day_requests.off_day_request_note,
					employee_id: this.off_day_requests.employee_id,
					off_day_date_answer: this.off_day_requests.off_day_request_final_date,
					user_id: this.user.user_id
				}
				offRequests.createOffDays(this.off_days_data).then(r => {
					if (r.status == 200) {
                        this.loading_btn = false
						this.off_days_data = {
							user_id: this.user.user_id
						}
						// this.rows.push(
						// 	r.data.new_data
						// )
						this.snackbar = {
							value: true,
							text: 'OffDays Added',
							color: 'success'
						}
						
					} else {
						this.snackbar = {
							value: true,
							text: 'Fail to add OffDays',
							color: 'error'
						}
					}
				})
					.finally(() => {
						this.loading_btn = false
                        
					})

			},
			addIligalDays() {
				this.loading_btn = true
                console.log(this.request_data)

				this.off_days = {
					iligal_day_date: new Date(this.request_data.off_day_date).toISOString().slice(0, 19).replace('T', ' '),
                    iligal_day_date_insert: new Date(this.request_data.off_day_request_date).toISOString().slice(0, 19).replace('T', ' '),
					iligal_day_amount: this.off_day_requests.off_day_request_amount,
					iligal_day_note: this.off_day_requests.off_day_request_note,
					employee_id: this.off_day_requests.employee_id,
					// off_day_date_answer: this.off_day_requests.off_day_request_final_date,
					user_id: this.user.user_id
				}
				iligalRequests.createIligalDays(this.off_days).then(r => {
					if (r.status == 200) {
                        this.loading = false
						this.off_days = {
							user_id: this.user.user_id
						}
						// this.rows.push(
						// 	r.data.new_data
						// )
						this.snackbar = {
							value: true,
							text: 'OffDays Added',
							color: 'success'
						}
						// this.loading = false
					} else {
						this.snackbar = {
							value: true,
							text: 'Fail to add OffDays',
							color: 'error'
						}
					}
				})
					.finally(() => {
						this.loading_btn = false
					})

			},
    

			getOneProfileAttachment() {
				this.loading = true
				profileRequests.getProfileAttachmentsByColumn('employee_id', this.idemployee).then(r => {
					if (r.status == 200) {
						this.profile_attachment = r.data.rows[0]
						this.loading = false

					} else {
						this.snackbar = {
							value: true,
							text: 'Fail to read ProfileAttachment',
							color: 'error'
						}
					}
				})
					.catch(e => {
						this.snackbar = {
							value: true,
							text: 'Fail to read ProfileAttachment',
							color: 'error'
						}
					})
					.finally(() => {
						this.loading = false
					})
			},
			getOneOffDayRequests() {
				this.loading = true
				requests.getOneOffDayRequests(this.id).then(r => {
					if (r.status == 200) {
						this.off_day_requests = r.data.row
                        // this.off_day_dates.insert = this.off_day_requests.off_day_request_insert_date plus 4 hours
                        this.off_day_dates.insert = new Date(new Date(this.off_day_requests.off_day_request_insert_date).setHours(new Date(this.off_day_requests.off_day_request_insert_date).getHours() + 7)).toISOString().slice(0, 19).replace('T', ' ')
						this.idemployee = this.off_day_requests.employee_id
						this.getOneEmployeeDetail()
						this.loading = false

					} else {
						this.snackbar = {
							value: true,
							text: 'Fail to read OffDayRequests',
							color: 'error'
						}
					}
				})
					.catch(e => {
						this.snackbar = {
							value: true,
							text: 'Fail to read OffDayRequests',
							color: 'error'
						}
					})
					.finally(() => {
						this.loading = false
					})
			},
			updateOffDayRequests(status) {
				this.loading = true
                // this.request_data = {
                //     off_day_date: this.off_day_requests.off_day_request_date,
                //     off_day_request_date: this.off_day_requests.off_day_request_insert_date
                // }
				delete this.off_day_requests.off_day_request_date
				delete this.off_day_requests.off_day_request_insert_date
				if (status == 'approved') {
					delete this.off_day_requests.off_day_request_answer_date
					this.off_day_requests.off_day_request_status = status
					this.off_day_requests.off_day_request_final_date = new Date().toISOString().slice(0, 19).replace('T', ' ');

				} else {
					delete this.off_day_requests.off_day_request_final_date
					delete this.off_day_requests.off_day_request_final_note
					this.off_day_requests.off_day_request_status = status
					this.off_day_requests.off_day_request_answer_date = new Date().toISOString().slice(0, 19).replace('T', ' ');
				}
				// this.off_day_requests.off_day_request_status = status
				// this.off_day_requests.off_day_request_answer_date = new Date().toISOString().replace('T', ' ').substring(0, 19)				        
				requests.updateOffDayRequests(this.id, this.off_day_requests).then(r => {
					if (r.status == 200) {
						// if (status == 'approved' && this.off_day_requests.off_day_request_type == 'off_day') {
                        //     this.addOffDays()
                          
						// }
                        // else if (status == 'approved' && this.off_day_requests.off_day_request_type == 'without_salary') {
                        //     this.addIligalDays()
                          
                        // }

						this.snackbar = {
							value: true,
							text: 'OffDayRequests Updated',
							color: 'success'
						}
						this.loading = false
					} else {
						this.snackbar = {
							value: true,
							text: 'Update Faild',
							color: 'error'
						}
						this.loading = false
					}
				})
					.catch(e => {
    this.snackbar = {
        value: true,
        text: 'Update Failed',
        color: 'error'
    };
    this.loading = false; // Ensure loading state is updated even in case of an error
})

					.finally(() => {
						this.loading = false
					})
			},
		},
	}
</script>
                    